var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":250,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',_vm._b({attrs:{"color":"error","content":_vm.notificationsUnreadCount,"value":_vm.notificationsUnreadCount,"overlap":""}},'v-badge',_vm.$attrs,false),[_c('v-btn',_vm._g({staticClass:"bell-btn",attrs:{"color":"#FFF","icon":""}},on),[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[(_vm.errorMessage)?_c('v-alert',{staticClass:"error-alert",attrs:{"dense":"","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(!_vm.notifications.length)?_c('div',{staticClass:"no-data-wrapper"},[_c('span',[_vm._v("Nenhuma notificação encontrada.")])]):_c('v-list',{attrs:{"dense":"","max-width":"400"}},[_vm._l((_vm.notifications),function(ref){
var criado_em = ref.criado_em;
var id = ref.id;
var message = ref.message;
var read = ref.read;
return _c('v-list-item',{key:id},[_c('v-list-item-content',{class:{ 'unread-message': !read },on:{"click":function($event){return _vm.markAsRead(id, read)}}},[_c('v-badge',{attrs:{"color":"error","dot":"","offset-x":"10","overlap":"","value":!read}},[(criado_em)?_c('p',{staticClass:"notification-created-at"},[_vm._v(_vm._s(_vm.formatTimeFromNow(criado_em)))]):_vm._e(),_c('p',{staticClass:"notification-message"},[_vm._v(_vm._s(message))])])],1)],1)}),(_vm.hasNextPage)?_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{on:{"click":function($event){return _vm.loadMore()}}},[_vm._v("Carregar mais")])],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }