<script>
import moment from "moment";

const pendingRequests = new Set();

export default {
  inheritAttrs: false,
  data() {
    return {
      errorMessage: "",
      loading: false,
      openMenu: false,
    };
  },
  computed: {
    hasNextPage() {
      const { page, lastPage } = this.$store.state.notification;
      return page < lastPage;
    },
    notifications() {
      return this.$store.state.notification.notifications;
    },
    notificationsUnreadCount() {
      const { unreadCount } = this.$store.state.notification;
      return unreadCount > 9 ? '+9' : unreadCount;
    },
  },
  methods: {
    formatTimeFromNow(iso) {
      moment.locale('pt-Br');
      return moment(iso).fromNow();
    },
    async getNotifications() {
      try {
        this.errorMessage = "";
        this.loading = true;
        await this.$store.dispatch("getNotifications");
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      this.$store.commit("incrementPage");
      this.getNotifications();
    },
    async markAsRead(id) {
      if (pendingRequests.has(id)) {
        return;
      }

      try {
        pendingRequests.add(id);
        this.errorMessage = "";
        await this.$store.dispatch("markNotificationAsRead", id);
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      }

      pendingRequests.delete(id);
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<template>
  <v-menu v-model="openMenu" :close-on-content-click="false" :nudge-width="250" offset-y>
    <template v-slot:activator="{ on }">
      <v-badge
        color="error"
        :content="notificationsUnreadCount"
        :value="notificationsUnreadCount"
        overlap
        v-bind="$attrs"
      >
        <v-btn class="bell-btn" color="#FFF" icon v-on="on">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-alert
      v-if="errorMessage"
      class="error-alert"
      dense
      text
      type="error"
    >
      {{errorMessage}}
    </v-alert>
    <div v-if="!notifications.length" class="no-data-wrapper">
      <span>Nenhuma notificação encontrada.</span>
    </div>
    <v-list v-else dense max-width="400">
      <v-list-item v-for="{ criado_em, id, message, read } in notifications" :key="id">
        <v-list-item-content :class="{ 'unread-message': !read }" @click="markAsRead(id, read)">
          <v-badge color="error" dot offset-x="10" overlap :value="!read">
            <p v-if="criado_em" class="notification-created-at">{{ formatTimeFromNow(criado_em) }}</p>
            <p class="notification-message">{{ message }}</p>
          </v-badge>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="hasNextPage">
        <v-list-item-content>
          <v-btn @click="loadMore()">Carregar mais</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
$accent: #f2f9ff;
$background: #FFF;
$scroll: var(--v-table-header-base);

.bell-btn {
  stroke: #fff;
  z-index: 1;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .loading-animation {
    animation: rotating 1s linear infinite;
  }
}

.error-alert {
  font-size: 0.75rem;
  margin: 0.5rem;
}

.notification-created-at,
.notification-message {
  margin-bottom: 0;
  width: calc(100% - 10px);
}

.notification-created-at {
  color: rgba($color: #000, $alpha: 0.7);
  font-size: .75rem;
}

.no-data-wrapper {
  padding: 0.75rem;
  text-align: center;
}

.unread-message {
  cursor: pointer;
}

.v-badge::v-deep .v-badge__badge {
  z-index: 1;
}

.v-list {
  padding: 0;
  background-color: $background;
}

.v-list-item {
  padding: .25rem 1rem;

  & + & {
    border-top: thin solid $accent;
  }

  &:hover {
    background-color: $accent;
  }
}

.v-menu__content {
  background-color: $background;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: $scroll transparent;
}
</style>
